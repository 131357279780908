import type { SvgIconComponent } from '@mui/icons-material'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'

interface CardData {
  id: number
  title: string
  description: string
  icon: string
}

const cardsData: CardData[] = [
  {
    id: 1,
    title: 'Busca rápida',
    description: 'Compare todas as ofertas de carros por assinatura em poucos segundos.',
    icon: '🚀'
  },
  {
    id: 2,
    title: 'Aprove seu cadastro',
    description: 'Nossa tecnologia verifica seu cadastro em todos os parceiros conforme a sua escolha.',
    icon: '⚡️'
  },
  {
    id: 3,
    title: 'Cuidamos de tudo',
    description: 'Assine através da comparaCAR e garanta o menor preço.',
    icon: '🫶🏻'
  }
]

interface FeatureCardProps {
  card: CardData
}

const FeatureCard = ({ card }: FeatureCardProps) => {
  const { icon, title, description } = card

  return (
    <Card elevation={1} sx={{ height: '100%' }}>
      <CardContent sx={{ pt: 3, pb: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h3" gutterBottom>
            {icon}
          </Typography>
          <Box>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default function InfoSection() {
  return (
    <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 3 }}>
      <Grid container spacing={3}>
        {cardsData.map(card => (
          <Grid key={card.id} size={{ xs: 12, md: 4 }}>
            <FeatureCard card={card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
