import Grid from '@mui/material/Grid2'
import Link from '@mui/material/Link'
import NextLink from 'next/link'
import React from 'react'

interface iLinksSectionProps {
  urlPrefix: string
  links: Link[]
}

const LinksSection: React.FC<iLinksSectionProps> = ({ urlPrefix, links }) => {
  if (!links.length) {
    return null
  }

  return (
    <section id="Links">
      <Grid container spacing={1}>
        {links.map(item => (
          <Grid key={item.slug} size={{ xs: 4, sm: 3, md: 2 }}>
            <NextLink href={`${urlPrefix}${item.slug}`} passHref legacyBehavior>
              <Link underline="always">{item.name}</Link>
            </NextLink>
          </Grid>
        ))}
      </Grid>
    </section>
  )
}

export default LinksSection
