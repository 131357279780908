import { Stack } from '@mui/material'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import { useRouter } from 'next/router'
import React from 'react'
import { useRecoilValue } from 'recoil'

import BrandListCarrousel from './BrandListCarrousel'
import SearchInput from '../generic/SearchInput'
import { carouselIconsState } from '../state/brands'

interface iMainSearch {
  engine: engine
}

const MainSearch: React.FC<iMainSearch> = ({ engine }) => {
  const carouselIcons = useRecoilValue(carouselIconsState)

  const router = useRouter()

  const updateUrl = (searchTerm: string) => {
    if (engine === 'subscribe') {
      router.push(`/assinar/carro/${searchTerm}`)
      return
    }
    if (engine === 'buy') {
      router.push(`/comprar/carro/${searchTerm}`)
      return
    }
    if (engine === 'rate') {
      router.push(`/avalieseucarro/carro/${searchTerm}`)
      return
    }
  }

  return (
    <Grid
      container
      paddingY={6}
      paddingX={3}
      bgcolor="background.paper"
      boxShadow={2}
      data-testid="SubscriptionSearch"
      borderRadius="0px 0px 8px 8px"
    >
      <Grid size={12} mb={7}>
        <Stack spacing={2}>
          <SearchInput
            fullWidth
            engine={engine}
            hideSeeAllButton={engine === 'rate'}
            hideManufacture={engine === 'rate'}
            onSelect={option => {
              updateUrl(option.value)
            }}
          />
        </Stack>
      </Grid>

      {engine === 'rate' && (
        <Grid size={12} mb={2}>
          <Typography variant="h6" align="center">
            Descubra o preço médio do seu carro
          </Typography>
        </Grid>
      )}

      {engine !== 'rate' && (
        <Grid size={{ xs: 12, md: 12 }}>
          <BrandListCarrousel brands={carouselIcons.map(brand => ({ ...brand, width: 100, height: 54.4 }))} />
        </Grid>
      )}
    </Grid>
  )
}

export default MainSearch
