import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import NextLink from 'next/link'
import React from 'react'

import CarMatchSvg from './CarMatchSvg'
import WhatsAppIcon from '../icons/WhatsApp'

export default function TalkSpecialistButton() {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button startIcon={<WhatsAppIcon />} onClick={handleClickOpen} fullWidth variant="contained" color="success">
        Fale com o especialista
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ p: 6, position: 'relative' }}>
          <Box sx={{ position: 'absolute', right: '8px', top: '8px' }}>
            <IconButton id="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Stack spacing={4} alignItems="center">
            <CarMatchSvg height="64" width="234" />
            <Typography variant="h4" textAlign="center">
              Precisamos de algumas informações para o nosso especialista poder te ajudar!
            </Typography>
            <Typography color="grey.800" textAlign="center">
              Basta clicar no botão e iniciar sua jornada.
            </Typography>
            <NextLink href="/carmatch" passHref legacyBehavior>
              <Button
                fullWidth
                sx={{ p: '22px 8px', color: theme.palette.common.black, fontWeight: '600', fontSize: '15px' }}
                size="large"
                color="secondary"
                variant="contained"
                endIcon={<ArrowForwardIosIcon />}
              >
                Iniciar Jornada
              </Button>
            </NextLink>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}
